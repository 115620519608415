import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Zoomable = ({ image, imagelarge, alt }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <>
      <div
        className="column"
        onClick={() => setIsActive(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => setIsActive(true)}
        aria-label="Zoomer"
      >
        <Img
          fixed={image.childImageSharp.fixed}
          alt={alt}
          className="zoomable"
          title={alt}
        />
      </div>
      <div className={`modal ${isActive ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setIsActive(false)}
          tabIndex={0}
          onKeyDown={() => setIsActive(false)}
          role="button"
          aria-label="Dézoomer"
        ></div>
        <div className="modal-content">
          <div className="image">
            <Img
              fixed={imagelarge.childImageSharp.fixed}
              alt={alt}
              style={{ width: 1000, height: 750 }}
            />
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsActive(false)}
          onKeyDown={() => setIsActive(false)}
        ></button>
      </div>
    </>
  )
}

const GiteFr = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      sdb: file(relativePath: { eq: "gite-sdb1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sdb2: file(relativePath: { eq: "gite-sdb2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sam1: file(relativePath: { eq: "gite-sam1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salon1: file(relativePath: { eq: "gite-salon1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine1: file(relativePath: { eq: "gite-cuisine1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine2: file(relativePath: { eq: "gite-cuisine2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrebleue: file(relativePath: { eq: "gite-chambre-bleue.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambreasia: file(relativePath: { eq: "gite-chambre-asia.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrechat: file(relativePath: { eq: "gite-chambre-chat.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vueext: file(relativePath: { eq: "gite-vueext.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin1: file(relativePath: { eq: "gite-jardin1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin2: file(relativePath: { eq: "gite-jardin2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ext: file(relativePath: { eq: "gite-ext.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chat2: file(relativePath: { eq: "gite-chat2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salleeau2e: file(relativePath: { eq: "gite-salle-eau-2e.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      elegante: file(relativePath: { eq: "gite-chambre-elegante.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura1: file(relativePath: { eq: "gite-jura1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura2: file(relativePath: { eq: "gite-jura2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura3: file(relativePath: { eq: "gite-jura3.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura4: file(relativePath: { eq: "gite-jura4.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      sdblarge: file(relativePath: { eq: "gite-sdb1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sdb2large: file(relativePath: { eq: "gite-sdb2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sam1large: file(relativePath: { eq: "gite-sam1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salon1large: file(relativePath: { eq: "gite-salon1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine1large: file(relativePath: { eq: "gite-cuisine1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine2large: file(relativePath: { eq: "gite-cuisine2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrebleuelarge: file(relativePath: { eq: "gite-chambre-bleue.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambreasialarge: file(relativePath: { eq: "gite-chambre-asia.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrechatlarge: file(relativePath: { eq: "gite-chambre-chat.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vueextlarge: file(relativePath: { eq: "gite-vueext.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin1large: file(relativePath: { eq: "gite-jardin1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin2large: file(relativePath: { eq: "gite-jardin2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      extlarge: file(relativePath: { eq: "gite-ext.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chat2large: file(relativePath: { eq: "gite-chat2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salleeau2elarge: file(relativePath: { eq: "gite-salle-eau-2e.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      elegantelarge: file(relativePath: { eq: "gite-chambre-elegante.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura1large: file(relativePath: { eq: "gite-jura1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura2large: file(relativePath: { eq: "gite-jura2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura3large: file(relativePath: { eq: "gite-jura3.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura4large: file(relativePath: { eq: "gite-jura4.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gitefr: file(relativePath: { eq: "gite-de-france.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <SEO
        title="Location gite meublé"
        description="Location chambre d'hôtes dans le Jura"
      />
      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <Img
            fixed={data.gitefr.childImageSharp.fixed}
            alt="Gite de France"
            style={{ width: 250 }}
          />
        </div>
        <div className="column pl-5 pr-5">
          <h2 className="mt-6">Gite Guyot</h2>
          <p>
            Loue gîte meublé de 150m² dans maison individuelle sur un terrain de
            20 ares, dont la moitié planté en vigne. Il est idéalement situé
            dans le village de <a href="http://www.montain.fr/">Montain</a>,
            entre <a href="https://www.lonslesaunier.fr/">Lons-le-Saunier</a> (6
            km) et <a href="http://www.voiteur.fr/">Voiteur</a> (7km) en plein
            cœur du{" "}
            <a href="https://www.bresse-revermont.fr/decouvrir-explorer-revermont-bresse/pays-du-revermont-montagnes-jura-aintourisme/">
              Revermont
            </a>{" "}
            au cœur du département du Jura.
          </p>
        </div>
        <div className="column">
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/UZFZ8rn09Lk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <h4 className="mt-5">Il se compose au premier étage :</h4>
      <p>
        D’une salle à manger et d’une partie salon, d’une cuisine équipée, d’un
        wc, d’une salle de bain avec lavabo double vasque, d’une première
        chambre avec lit de 160 motorisé avec placard et commode (chambre
        bleue), d’une deuxième chambre (Asia) avec un lit de 160 et d’un meuble
        et d’un placard dans une décoration asiatique et zen.
      </p>
      <p>
        Un balcon permet de profiter du paysage et de la tranquillité des lieux.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.sam1}
          imagelarge={data.sam1large}
          alt="Salle à manger"
        />
        <Zoomable
          image={data.salon1}
          imagelarge={data.salon1large}
          alt="Salon"
        />
        <Zoomable
          image={data.cuisine1}
          imagelarge={data.cuisine1large}
          alt="Cuisine"
        />
        <Zoomable
          image={data.cuisine2}
          imagelarge={data.cuisine2large}
          alt="Cuisine"
        />
        <Zoomable
          image={data.sdb}
          imagelarge={data.sdblarge}
          alt="Salle de bains"
        />
        <Zoomable
          image={data.sdb2}
          imagelarge={data.sdb2large}
          alt="Salle de bains"
        />
        <Zoomable
          image={data.chambrebleue}
          imagelarge={data.chambrebleuelarge}
          alt="Chambre bleue"
        />
        <Zoomable
          image={data.chambreasia}
          imagelarge={data.chambreasialarge}
          alt="Chambre asia"
        />
      </div>
      <h4 className="mt-5">Au deuxième étage :</h4>
      <p>
        Une troisième chambre (Chat), plus cocooning avec un lit de 140, bureau
        et placard, une quatrième chambre avec un lit king size de 2m/2m et une
        très belle vue sur le village de{" "}
        <a href="https://fr.wikipedia.org/wiki/Lavigny_(Jura)">Lavigny</a>,
        enfin une salle d’eau équipée de wc.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.chat2}
          imagelarge={data.chat2large}
          alt="Chambre chat"
        />
        <Zoomable
          image={data.elegante}
          imagelarge={data.elegantelarge}
          alt="Chambre élégante"
        />
        <Zoomable
          image={data.chambrechat}
          imagelarge={data.chambrechatlarge}
          alt="Chambre lit king size"
        />
        <Zoomable
          image={data.salleeau2e}
          imagelarge={data.salleeau2elarge}
          alt="Salle d'eau au 2e étage"
        />
      </div>
      <h4 className="mt-5">A l'extérieur :</h4>
      <p>
        Le terrain extérieur qui entoure la maison permet soit de se reposer sur
        les transats mis à votre disposition, soit de vous restaurer à la
        plancha ou encore vous défouler avec des jeux de plein air (pétanque,
        molky, ballon).
      </p>
      <p>
        Une large et grand cour permet un stationnement de plusieurs véhicules
        si vous venez avec de la famille ou des amis.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.jardin1}
          imagelarge={data.jardin1large}
          alt="Salon de jardin"
        />
        <Zoomable
          image={data.jardin2}
          imagelarge={data.jardin2large}
          alt="Saule pleureur"
        />
        <Zoomable image={data.ext} imagelarge={data.extlarge} alt="Extérieur" />
        <Zoomable
          image={data.vueext}
          imagelarge={data.vueextlarge}
          alt="Vue sur l'extérieur"
        />
      </div>
      <h3 className="mt-6">Tarifs</h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table className="table mt-3">
          <thead>
            <tr>
              <th>Périodes</th>
              <th>Tarifs</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ background: "#ffdea0" }}>
              <td>
                <strong style={{ color: "#b62222" }}>Juillet Aout</strong>{" "}
                <em>uniquement du samedi au samedi</em>
              </td>
              <td>950 €</td>
            </tr>
            <tr style={{ background: "#ffdea0" }}>
              <td>
                <strong style={{ color: "#b62222" }}>Noel et Nouvel an</strong>{" "}
                <em>uniquement du samedi au samedi</em>
              </td>
              <td>950 €</td>
            </tr>
            <tr style={{ background: "#ffdea0" }}>
              <td>
                <strong style={{ color: "#b62222" }}>
                  Vacances de Pâques, Février et Toussaint
                </strong>{" "}
                <em>uniquement du samedi au samedi</em>
              </td>
              <td>800 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>
                Basse saison automne/ hiver (janvier, février*, mars, octobre,
                novembre, décembre ) pour une semaine
              </td>
              <td>650 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>2 nuits</td>
              <td>325 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>3 nuits</td>
              <td>418 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>4 nuits</td>
              <td>511 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>5 nuits</td>
              <td>604 €</td>
            </tr>
            <tr style={{ background: "#ffa0a0" }}>
              <td>6 nuits</td>
              <td>650 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>
                Basse saison printemps/été (avril, mai, juin, septembre) pour
                une semaine
              </td>
              <td>750 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>2 nuits</td>
              <td>375 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>3 nuits</td>
              <td>482 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>4 nuits</td>
              <td>589 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>5 nuits</td>
              <td>696 €</td>
            </tr>
            <tr style={{ background: "#c2ffa0" }}>
              <td>6 nuits</td>
              <td>750 €</td>
            </tr>
            <tr style={{ background: "#e3e3e3" }}>
              <td>Acompte à la signature du contrat</td>
              <td>25 %</td>
            </tr>
            <tr style={{ background: "#e3e3e3" }}>
              <td>Caution (pour une semaine)</td>
              <td>650</td>
            </tr>
            <tr style={{ background: "#e3e3e3" }}>
              <td>Forfait ménage</td>
              <td>150</td>
            </tr>
            <tr style={{ background: "#e3e3e3" }}>
              <td>Fourniture drap de lit ( par lit)</td>
              <td>12</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2">* hors vacances</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <h3 className="mt-6">Le Jura</h3>
      <p>
        La situation de ce gîte permet de profiter pleinement et rapidement de
        la visite des{" "}
        <a href="https://www.jura-tourism.com/">
          plus beaux sites touristiques du Jura
        </a>{" "}
        dont la proximité immédiate de deux plus beaux villages de France (
        <a href="http://www.baumelesmessieurs.fr/">Baume-les-Messieurs</a> et{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/villes-et-villages/chateau-chalon/">
          Château-Chalon
        </a>
        ), du vignoble et de nombreux lacs et lieux de baignade (
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/villes-et-villages/clairvaux-les-lacs/">
          Clairvaux-les-Lacs
        </a>
        ,{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/les-incontournables/lac-de-chalain/">
          Chalain
        </a>
        ,{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/les-incontournables/lac-de-vouglans/">
          Vouglans
        </a>{" "}
        et <a href="https://www.loisirsjura.fr/">Desnes</a>). De la proximité de
        la ville préfecture de{" "}
        <a href="https://www.lonslesaunier.fr/">Lons-le-Saunier</a> avec ses
        nombreux musées et équipements, cinéma, piscine, médiathèque, bowling et
        bien sûr commerces.
      </p>
      <p>
        Le Jura est également un département propice à la pratique de nombreuses
        activitées sportives, dont la randonnée que vous pourrez pratiquer seuls
        ou{" "}
        <Link to="/fr/accompagnateur-de-tourisme/">
          accompagnés du propriétaire des lieux
        </Link>{" "}
        et ainsi bénéficier de ses conseils et connaissances.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.jura1}
          imagelarge={data.jura1large}
          alt="Beaume abbaye"
        />
        <Zoomable
          image={data.jura2}
          imagelarge={data.jura2large}
          alt="Tour du Meix pont de la pyle"
        />
        <Zoomable
          image={data.jura3}
          imagelarge={data.jura3large}
          alt="Château-Chalon rocher"
        />
        <Zoomable
          image={data.jura4}
          imagelarge={data.jura4large}
          alt="Cascade hérisson"
        />
      </div>
      <p>
        Ce gîte vous permettra le dépaysement mais également le confort de vous
        sentir comme à la maison.
      </p>
      <p>
        N’hésitez pas à <Link to="/fr/contact/">me contacter</Link> et à{" "}
        <a href="https://www.facebook.com/Location-maison-Jura-101060245025335/">
          visiter ma page facebook
        </a>{" "}
        ou encore{" "}
        <a href="https://m.me/101060245025335">me contacter sur messenger</a>.
      </p>
      <p>
        A très vite et au plaisir de vous accueillir et vous faire découvrir le
        Jura.
      </p>
      <p>Ludovic</p>
      <p className="is-italic mt-4 has-text-right is-size-7">
        Crédit photo Albert Wolff et Ludovic Guyot
      </p>
    </Layout>
  )
}

export default GiteFr
